import axios from "axios";
import Conversation from "../classes/conversation";
import MessageRequest from "../classes/messageRequest";

const appCacheCounter = "1"

export function appName() {
    return "Betriebsratsbot";
}

export function disclaimer() {
    return "Ich bin ein KI Bot und kann Fehler machen. Wichtige Informationen sollten überprüft werden! Dies ist keine Rechtsberatung."
}

export function hasToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key) !== null;
}

export function token() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key);
}

export function setToken(token) {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.setItem(key, token);
}

export function removeToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.removeItem(key);
}

export function cacheConversation(conversation) {
    localStorage.setItem("conversation cache - cache number:" + appCacheCounter, JSON.stringify(conversation));
}

export function clearConversation() {
    localStorage.removeItem("conversation cache - cache number:" + appCacheCounter);
}

export function getConversation() {
    const storageConversation = localStorage.getItem("conversation cache - cache number:" + appCacheCounter);
    if (storageConversation === null) {
        return null;
    }
    const conversation = JSON.parse(storageConversation);
    const newConversation = new Conversation(conversation.id, conversation.title);
    const messageRequests = [];
    for (const messageRequest of conversation.messageRequests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    newConversation.setMessageRequests(messageRequests);
    return newConversation;
}

export function clearCache() {
    clearConversation();
    removeToken();
    clearConversation();
}

function parseConversation(fetchConversation) {
    const conversation = new Conversation(fetchConversation.id);
    const messageRequests = [];
    if (fetchConversation.title) {
        conversation.setTitle(fetchConversation.title);
    }
    if (fetchConversation.created_at) {
        conversation.setCreatedAt(fetchConversation.created_at);
    }
    if (!fetchConversation.message_requests) {
        return conversation;
    }
    for (const messageRequest of fetchConversation.message_requests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    conversation.setMessageRequests(messageRequests);
    return conversation;
}

export async function getConversations(projectId=null, with_messages=false) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.get(url, {
            params: {
                with_messages: with_messages
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversations = response.data.map(parseConversation);
        return conversations;
    } catch (error) {
        console.log(error);
        // Handle error here
    }
}

export async function getConversationById(id, projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations/' + id;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = parseConversation(response.data);
        return conversation;
    } catch (error) {
        console.log(error);
        // Handle error here
    }
}

export async function createConversation(projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.post(url, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = new Conversation(response.data.id);
        return conversation;
    } catch (error) {
        console.log(error);
        // Handle error here
    }
}

export async function getProject() {
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID;
    console.log(process.env)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        return response;
    } catch (error) {
        return error.response;
        // Handle error here
    }
}

export async function generateDocument(prompt, conversation_id=null, useBetriebsratsBotSources=true, project_ids=[]) {
    if (useBetriebsratsBotSources) {
        project_ids.push(process.env.REACT_APP_PROJECT_ID)
    }
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/generate_document", JSON.stringify({project_ids: project_ids, prompt: prompt, conversation_id: conversation_id}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
    } catch (error) {
        return error.response;
    }
    return response;
}


export async function downloadAsDocx(conversationId, message_request_id, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/conversations/" + conversationId + "/message_requests/" + message_request_id + "/generate_docx", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            },
            responseType: 'blob'
        });
    } catch (error) {
        return error.response;
    }
    return response;
}

export function reduceToShortText(text, length=30) {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
}

export function parseDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Tag (immer 2-stellig)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monat (0-basiert, daher +1)
    const year = date.getFullYear(); // Jahr

    // Das Ergebnis im Format TT.MM.JJJJ
    return `${day}.${month}.${year}`;
}

export function ratingChanged(newRating, messageID, projectId = null)  {
    projectId = projectId ? projectId : process.env.REACT_APP_PROJECT_ID
    const json = JSON.stringify({rating: newRating})
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/chat/' + messageID;
    axios.patch(url, json, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token(),
        }
    })
}
