import React from 'react';
import { useState, useEffect } from 'react';
import ChatPrompt from './chatPrompt';
import ChatReply from './chatReply';
import Conversation from '../../classes/conversation';
import { TfiPencilAlt } from "react-icons/tfi";
import { LuInfo } from "react-icons/lu";

import { reduceToShortText, createConversation, cacheConversation, getConversation, getConversations, getConversationById, disclaimer } from '../../utils/utils';

function Chat({ authenticated, logout, setAuthenticated }) {
    const [chatFull, setChatFull] = useState(false);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const [chevron, setChevron] = useState('down');
    const [callToAction, setCallToAction] = useState(true);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [conversation, setConversation] = useState(getConversation());
    
    const showConversationCount = 10

    async function fetchConversations(onError) {
        getConversations().then((conversations) => {
          setConversations(conversations);
        }).catch(() => {
          onError();
          alert('Dein Token ist abgelaufen, nicht korrekt oder der Server ist gerade nicht erreichbar, bitte logge dich später erneut ein')
        });
    }
    useEffect( () => {
        if (authenticated) {
            const fetchConversation = async () => {
                const updatedConversation = await getConversationById(conversation.id);
                if (updatedConversation) {
                    cacheConversation(updatedConversation);
                    setConversation(updatedConversation);
                }
            };
            const loadConversation = async () => {
                if (conversation?.id) {
                    await fetchConversation();
                } else {
                    await newConversation();
                }
                await fetchConversations(logout);
                }
            loadConversation();
        }
    }, [authenticated]);

    async function selectConversation(e) {
        setShowCommentBox(false);
        const conversation = await getConversationById(e.target.dataset.value);
        if (conversation) {
            cacheConversation(conversation);
            setConversation(conversation);
        }
    }
    
    async function newConversation() {
        const conversation = await createConversation();
        if (conversation) {
            cacheConversation(conversation);
            setConversation(conversation);
        }
    }

    const newChat = () => {
        setConversation(new Conversation());
        newConversation();
        setCallToAction(true);
        setShowCommentBox(false);
        setChatFull(false);
        setComment('Dein Feedback ...');
        setRating(0);
    }

    function openSelect(e) {
        if (showSelect) {
          closeSelect();
          return
        }
        e.stopPropagation();
        e.preventDefault();
        setChevron('up');
        setShowSelect(true);
        document.addEventListener('click', closeSelect);
    }
    
    const closeSelect = () => {
        setChevron('down');
        setShowSelect(false);
        document.removeEventListener('click', closeSelect);
    }

    const conversationsToShow = () => {
        if (conversations.length > showConversationCount) {
            // sort by createdAt date, newest first
            const sortedConversations = conversations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            return sortedConversations.slice(0, showConversationCount);
        }
        return conversations;
    }

    return (
        <div className='Chat'>
            <div className='Chat-action-buttons'>
                <button className='new-chat-button' onClick={newChat}>Neuer Chat <TfiPencilAlt /></button>
                <div className='conversation-select'>
                    {showSelect && <ul className='conversation-list'>
                        {conversationsToShow().map((conv) => (
                            <li className="conversation" key={conv.id} data-value={conv.id} onClick={selectConversation}>
                                {reduceToShortText(conv.title)}
                            </li>
                        ))}
                    </ul>}
                    <span className='conversation-select-button' onClick={openSelect}>
                        Chatverlauf <div className={'chevron ml-5-em chevron-' + chevron}></div>
                    </span>
                </div>
            </div>
            <ChatReply rating={rating} setRating={setRating} setComment={setComment} comment={comment} conversation={conversation} setCallToAction={setCallToAction} callToAction={callToAction} setShowCommentBox={setShowCommentBox} showCommentBox={showCommentBox}/>
            <ChatPrompt setAuthenticated={setAuthenticated} setRating={setRating} setComment={setComment} conversation={conversation} setConversation={setConversation} setCallToAction={setCallToAction} setShowCommentBox={setShowCommentBox} chatFull={chatFull} setChatFull={setChatFull} fetchConversations={fetchConversations}/>
            <div className='Chat-disclaimer'>{disclaimer()}</div>
            <div className='tooltip info-icon'>
                <LuInfo size={25} color='green' />
                <span className="tooltiptext">Ich bin ein KI Bot.
                    Ich helfe Betriebsräten dabei, schnell Antworten auf Fragen zu finden, die in ihrem Alltag auftreten.
                    Du kannst mir Fragen zur Betriebsratsarbeit stellen und ich versuche die bestmögliche Antwort zu geben.
                    Damit ich besser werden kann, wäre es toll, wenn du mir für jede Frage ein Feedback gibst.
                    Ich speichere die Chatverläufe, um daraus zu lernen.
                </span>
            </div>
        </div>
    );
}

export default Chat;