import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/login/login';
import { hasToken, clearCache, appName } from './utils/utils';
import * as Sentry from "@sentry/react";
import Header from './components/header';
import Chat from './components/Chat/chat';
import HelpPage from './components/helpPage';
import DocumentsPage from './components/documentsPage';
import ContactPage from './components/contactPage';
import SettingsPage from './components/settingsPage';

function App() {
  const [authenticated, setAuthenticated] = useState(hasToken() || false);
  
  const logout = () => {
    setAuthenticated(false);
    clearCache();
  }

  return (
    <div className="App">
      <Sentry.ErrorBoundary fallback={<p>"An error has occurred"</p>}>
        {authenticated ?
        <Router>
        <div className='page-container'>
          <Header logout={logout} />
          <div className='page-content'>
            <div className='Chat-header'>{appName()}</div>
            <Routes>
              {["/", "/chat"].map(
                path => <Route path={path} element={
                  <Chat authenticated={authenticated} logout={logout} setAuthenticated={setAuthenticated} />
                }/>
                )
              }
              <Route path='/help' element={<HelpPage />} />
              <Route path='/documents' element={<DocumentsPage />} />
              <Route path='/mail' element={<ContactPage />} />
              <Route path='/settings' element={<SettingsPage />} />
            </Routes>
          </div>
          <div className='footer'>
            <a target="_blank" rel="noopener noreferrer" href='https://betriebsratsbot.ai/impressum/'>Impressum</a>
            <a target="_blank" rel="noopener noreferrer" href='https://betriebsratsbot.ai/datenschutz/'>Datenschutz</a>
          </div>
        </div>
        </Router> 
        : 
        <Login setAuthenticated={setAuthenticated}/> }
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
