import MessageRequest from './messageRequest';

export default class Conversation {
    constructor(id=null, title='', created_at=null, messageRequests=[]) {
        this.messageRequests = messageRequests || new Array;
        this.id = id;
        this.title = title;
        this.createdAt = created_at
    }

    setCreatedAt(createdAt) {
        this.createdAt = createdAt;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        this.title = title;
    }

    setMessageRequests(messageRequests) {
        this.messageRequests = messageRequests;
    }

    addPrompt(prompt) {
        const messageRequest = new MessageRequest(prompt);
        this.messageRequests.push(messageRequest);
        return this.copy();
    }

    getMessageRequests() {
        return this.messageRequests;
    }

    hasUnansweredRequest() {
        return this.messageRequests.length > 0 && this.messageRequests[this.messageRequests.length - 1].getReply() === null;
    }

    setLastReply(reply) {
        const messageRequest = this.messageRequests[this.messageRequests.length - 1];
        messageRequest.setReply(reply);
        return this.copy();
    }

    copy() {
        const conversation = new Conversation(this.id);
        conversation.messageRequests = this.messageRequests;
        return conversation;
    }
}