import React from 'react';

function SettingsPage() {
    return (
        <div>
            <h1>Einstellungen</h1>
            <p>Die Einstellungen-Seite wird bald verfügbar sein.</p>
            <p>Hier finden Sie Informationen zu Abonnementdetails, Profilinformationen und anderen Einstellungen.</p>
        </div>
    );
};

export default SettingsPage;