import React from "react";
import { useState } from "react";
import { LuInfo } from "react-icons/lu";
import { setToken, getProject, removeToken } from "../../utils/utils";

export default function Login({ setAuthenticated }) {
    const [credential, setCredential] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        setToken(credential);
        let error = false;
        await getProject().then(response => {
            if (response === undefined) {
                removeToken();
                alert('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
                return;
            } else if (response.status > 299) {
                removeToken();
                alert('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
                return;
            } else {
                setAuthenticated(true);
            }
        }).catch(error => {
            removeToken();
            alert('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
            error = true;
            return;
        })
    }
    return (
        <div className='Login'>
            <div className='Login__header'>
                <img src='/robot.png' width={60} height={60} alt="Robot" title='Home'/>
                <span className='Login__title'>Betriebsratsbot</span>
            </div>
            <h2 className="Login__greeting--start">Moin,</h2>
            <h2 className="Login__greeting--end">Willkommen</h2>
            <input className='Login__input' type='text' placeholder="Authentifizierungstoken..." onChange={(e) => setCredential(e.target.value)} />
            <button className="Login__button" onClick={submit}>Anmelden</button>
            <div className='tooltip info-icon'>
                <LuInfo size={35} color='black' />
                <span className="tooltiptext"> Nach Abschluss eines Abonnements senden wir Ihnen das für die Anmeldung erfordigte Token per E-Mail zu.
                    Falls Sie das Token nicht erhalten haben oder es verloren gegangen ist, kontaktieren Sie uns bitte.
                </span>
            </div>
        </div>
    )
}
