import React from 'react';

const ContactPage = () => {
    const email = 'info@betriebsratsbot.ai';

    const mailto = () => {
        window.location.href = `mailto:${email}`;
    }

    return (
        <div>
            <div className='contact-page__header'>Kontakt</div>
            <div className='contact-page__content'>
                <p className='contact-page__text'>
                Du hast ein Anliegen und benötigst zum Beispiel hilfe, möchtest dein Abo ausbauen oder kündigen oder hast Vorschläge für
                weitere Funktionen? Melde dich in jedem Falle gern bei uns. Schilder kurz dein Anliegen und wir nehmen zeitnah Kontakt zu
                dir auf.
                </p>
                <div onClick={mailto} className='contact-page__mailto-button'>
                    E-Mail
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
