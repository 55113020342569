import { useState, useEffect, React } from "react";
import { TfiPencilAlt } from "react-icons/tfi";
import { downloadAsDocx, generateDocument, getConversations, disclaimer, ratingChanged, createConversation, getConversationById } from "../utils/utils";
import ConversationsComponent from "./conversationsComponent";
import ReactStars from "react-rating-stars-component";
import TextareaAutosize from 'react-textarea-autosize';
import { MdOutlineFileCopy } from "react-icons/md";
import { MdOutlineCloudDownload } from "react-icons/md";
import { VscRefresh } from "react-icons/vsc";

export default function DocumentGenerationPage() {
    const [prompt, setPrompt] = useState('');
    const [generatedDocument, setGeneratedDocument] = useState('');
    const [conversation, setConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [messageRequest, setMessageRequest] = useState(null);
    const [rating, setRating] = useState(0);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [callToAction, setCallToAction] = useState(true);

    const timeout = 60;

    const hasDocument = !(generatedDocument === '' || generatedDocument === "Generating" || generatedDocument === "Regenerating");

    useEffect(() => {
        refreshConversations();
        async function fetchConversation() {
            if (!conversation) {
                setConversation(await createConversation(process.env.REACT_APP_TEMPLATE_PROJECT_ID));
            }
        }
        fetchConversation();
    }, []);

    async function refreshConversation() {
        const updatedConversation = await getConversationById(conversation.id, process.env.REACT_APP_TEMPLATE_PROJECT_ID);
        if (updatedConversation) {
            setConversation(updatedConversation);
            setMessageRequest(getLatestMessageRequest(updatedConversation));
        }
    }

    const rate = (newRating) => {
        setRating(newRating);
        setShowCommentBox(true);
        ratingChanged(newRating, messageRequest.id, process.env.REACT_APP_TEMPLATE_PROJECT_ID);
    }

    function getLatestMessageRequest(conv) {
        if (conv.messageRequests.length > 0) {
            return conv.messageRequests[conv.messageRequests.length - 1];
        }
        return null;
    }

    async function refreshConversations() {
        getConversations(process.env.REACT_APP_TEMPLATE_PROJECT_ID, true).then((response) => {
            setConversations(response);
        });
    }

    async function downloadDocx() {
        if (hasDocument) {
            downloadAsDocx(conversation.id, messageRequest.id).then((response) => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                var name = conversation.title;
                if (name === '') {
                    name = 'document';
                }
                link.setAttribute('download', `${name}.docx`);
                document.body.appendChild(link);
                link.click();
            });
        }
    }


    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };

    async function generate() {
        const now = new Date();
        setRating(0);
        if (generatedDocument === '') {
            setGeneratedDocument('Generating');
        } else {
            setGeneratedDocument('Regenerating');
        }
        setCallToAction(false);
        generateDocument(prompt, conversation.id).then(async (response) => {
            if (response.status === 502) {
                var lastMessageSinceRequest = null;
                var updatedConversation = null;
                const timeSinceLastMessage = () => {return new Date().getTime() - now.getTime()};
                while (lastMessageSinceRequest === null && timeSinceLastMessage() < timeout * 1000) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    updatedConversation = await getConversationById(conversation.id, process.env.REACT_APP_TEMPLATE_PROJECT_ID);
                    if (updatedConversation.messageRequests.length > 0) {
                        lastMessageSinceRequest = getLatestMessageRequest(updatedConversation);
                        if (new Date(lastMessageSinceRequest.createdAt) < now) {
                            lastMessageSinceRequest = null;
                        }
                    }
                }
                if (lastMessageSinceRequest) {
                    setConversation(updatedConversation);
                    setMessageRequest(lastMessageSinceRequest);
                    setGeneratedDocument(lastMessageSinceRequest.reply);
                    setPrompt('');
                    setCallToAction(true);
                } else {
                    setGeneratedDocument("Leider konnte keine Antwort generiert werden. Bitte versuche es später erneut.");
                    setPrompt('');
                }
            } else {
                refreshConversation();
                setGeneratedDocument(response.data.reply);
                setPrompt('');
                setCallToAction(true);
            }
        });
    }

    async function newConversation() {
        setGeneratedDocument('');
        setConversation(await createConversation(process.env.REACT_APP_TEMPLATE_PROJECT_ID));
        setPrompt('');
        setRating(0);
    }

    function onKeyDown(event) {
        if (event.keyCode === 13) {
            generate();
        }
    }

    function highlightParent(event) {
        event.target.parentElement.classList.add("highlight")
    }

    function removeHighligthFromParent(event) {
        event.target.parentElement.classList.remove("highlight")
    }

    function copyToClipboard(e) {
        e.stopPropagation();
        const hidden = document.getElementsByClassName("copy-text")[0];
        hidden.classList.remove('hidden');
        setTimeout(() => {
            hidden.classList.add('hidden');
        }, 1000);
        if (hasDocument) {
            if (typeof ClipboardItem !== "undefined") {
                // Shiny new Clipboard API, not fully supported in Firefox.
                // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
                const html = new Blob([generatedDocument], { type: "text/html" });
                const data = new ClipboardItem({ "text/html": html});
                navigator.clipboard.write([data]);
            } else {
                // Fallback using the deprecated `document.execCommand`.
                // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
                const cb = e => {
                    e.clipboardData.setData("text/html", generatedDocument);
                    e.preventDefault();
                };
                document.addEventListener("copy", cb);
                document.execCommand("copy");
                document.removeEventListener("copy", cb);
            }
        }

    }

    function Document() {
        if (generatedDocument === "Generating") {
            return <div className="document-generation-page__loading-container">
                Ich erstelle ein passendes Dokument. Das kann bis zu einer Minute dauern.
                <span className="dot-flashing loading-dots"></span>
            </div>
        } else if (generatedDocument === "Regenerating") {
            return <div className="document-generation-page__loading-container">
                Ich bearbeite die letzte Version deines Dokumentes. Das kann ein bisschen dauern.
                <span className="dot-flashing loading-dots"></span>
            </div>
        } else if (generatedDocument === '') {
            return  <p className="document-generation-page__document-preview-text"></p>
        } else {
            return (
                <div>
                    <div className="document-generation-page__document-preview document-generation-page__document-preview--filled" dangerouslySetInnerHTML={{__html: generatedDocument}}>
                    </div>
                    <div className='rating'>
                                    <ReactStars
                                        count={5}
                                        value={rating}
                                        onChange={rate}
                                        size={24}
                                        activeColor="#ffd700"
                                    />
                        {callToAction &&<div className="rating-text">Bitte bewerte die Antwort</div>}
                    </div>
                </div>
            )
        }
    }
// TODO Comment after rating change, rating change reset after switch
    return (
        <div>
            <div className="document-generation-page">
                <div className="document-generation-page__content">
                    <div className="document-generation-page__header">Dokumentenerstellung
                        <div className="document-generation-page__header-symbols">
                            <div>
                                <MdOutlineFileCopy size={30} title="Inhalt kopieren" onClick={copyToClipboard}/>
                                <div className='hidden copy-text absolute-0'>{hasDocument ? "Dokument kopiert!" : "Nichts zum kopieren!"}</div>
                            </div>
                            <MdOutlineCloudDownload size={30} title="Als Worddatei (.docx) herunterladen" onClick={downloadDocx}/>
                            <VscRefresh size={30} title="Neues Dokument anfangen" onClick={newConversation}/>
                        </div>
                    </div>
                    <div className="document-generation-page__workspace">
                        <Document></Document>
                    </div>
                    {generatedDocument != '' ?
                        <div className="document-generation-page__choice-container">
                            <div className="document-generation-page__choice-prompt-container">
                                <TextareaAutosize
                                    onBlur={removeHighligthFromParent}
                                    onFocus={highlightParent}
                                    type="text"
                                    value={prompt}
                                    onChange={handlePromptChange}
                                    onKeyDown={onKeyDown}
                                    placeholder="Welche Anpassungswünsche hast du?"
                                    className="document-generation-page__choice-prompt"
                                />
                                <button
                                    onClick={generate}
                                    disabled={prompt.length === 0}
                                    title={prompt.length === 0 ? "Gib einen Text ein" : ""}
                                    style={prompt.length > 0 ? {cursor: "pointer"} : {}}
                                    className="document-generation-page__choice-regenerate-button"
                                >
                                    &rarr;
                                </button>
                            </div>
                            <span style={{fontStyle: "italic"}}>oder</span>

                            <div className="document-generation-page__new-document-container"  onClick={newConversation}>
                                <span className="document-generation-page__new-document-text">Neues Dokument erstellen</span>
                                <TfiPencilAlt/>
                            </div>
                        </div> 
                        : 
                        <div className="document-generation-page__prompt-container">
                            <TextareaAutosize
                                type="text"
                                onBlur={removeHighligthFromParent}
                                onFocus={highlightParent}
                                value={prompt}
                                onChange={handlePromptChange}
                                onKeyDown={onKeyDown}
                                placeholder="Welches Dokument möchtest du generieren und welche Informationen soll es enthalten?"
                                className="document-generation-page__prompt-input"
                            />
                                <button
                                    onClick={generate}
                                    disabled={prompt.length === 0}
                                    title={prompt.length === 0 ? "Gib einen Text ein" : ""}
                                    style={prompt.length > 0 ? {cursor: "pointer"} : {}}
                                    className="document-generation-page__generate-button"
                                >
                                    &rarr;
                                </button>
                        </div>
                    }
                    <div className='document-generation-page__disclaimer'>{disclaimer()}</div>
                </div>
                <ConversationsComponent conversations={conversations}  conversation={conversation} setConversation={setConversation} setGeneratedDocument={setGeneratedDocument} setCallToAction={setCallToAction} setMessageRequest={setMessageRequest} setRating={setRating}/>
            </div>
        </div>
    );
}

