import React from 'react';
import { useState } from 'react';
import { reduceToShortText, parseDate } from '../utils/utils';

function ConversationComponent({ conversation, currentConversation, setConversation, setGeneratedDocument, setCallToAction, setMessageRequest, setRating }) {
    const [collapsed, setCollapsed] = useState(true);

    const lastReply = conversation.messageRequests.length > 0 ? conversation.messageRequests[conversation.messageRequests.length - 1].reply : null;

    function selectConversation(event) {
        setCallToAction(true);
        event.stopPropagation();
        if (collapsed) {
            setConversation(conversation);
            setGeneratedDocument(lastReply);
            setRating(0);
            setCollapsed(false);
            setMessageRequest(conversation.messageRequests[conversation.messageRequests.length - 1]);
        } else if (conversation.id === currentConversation.id) {
            setCollapsed(true);
        } else {
            setConversation(conversation);
            setRating(0);
            setGeneratedDocument(lastReply);
            setMessageRequest(conversation.messageRequests[conversation.messageRequests.length - 1]);
        }
    }

    function selectReply(event, request) {
        setRating(0);
        event.stopPropagation();
        setGeneratedDocument(request.reply);
        setConversation(conversation);
        setMessageRequest(request);
        setCallToAction(true);
    }
    let messageRequests = conversation.messageRequests;
    if (messageRequests.length === 0) {
        return
    } else {
        // sort message requests by date and time in a string
        messageRequests = messageRequests.sort((a, b) => {
            return a.createdAt.localeCompare(b.createdAt);
        }
        );
    }

    return (
        <li>
            <h4 title={conversation.title} style={{cursor: "pointer"}} onClick={selectConversation} className='hover-pointer'>
                {reduceToShortText(conversation.title, 30) + " " + parseDate(conversation.createdAt)}
            </h4>
            <ol>
                {!collapsed && messageRequests.map((request) => (
                    <li key={request.id}>
                        <h3 title={request.prompt} className='hover-pointer' style={{cursor: "pointer"}} onClick={(event) => selectReply(event, request)}>Version</h3>
                    </li>
                ))}
            </ol>
        </li>
    );
};

export default ConversationComponent;